html {
  font-family: 'Roboto', sans-serif;
}

.content-wrapper {
  margin-top: 6em;
  width: 80%;
  margin-left: 10%;
}

h1 {
  font-weight: 700;
}

#wgrnh-text {
  font-weight: 300;
  margin-top: 2em;
  font-size: xx-large;
  text-align: center;
}
